<template>
    <div class="tariff">
        <v-progress-circular indeterminate v-if="loading" />
        <div v-if="!settings && !loading || settings.tariff.default && !loading || $store.state.profile.is_agency && !loading">
          <div v-if="isMobile" class="tariff__list">
            <swiper style="width:80vw;" :options="swiperOptions" ref="mySwiper">
              <swiper-slide style="width: 70vw;">
                <label style="width: 70vw;" class="tariff__item"
                       :class="{active: tariff == 1}"
                >
                  <h3 class="tariff__title">
                    Free
                    <span class="tariff__badge" v-if="current == 1">{{$t('tariff["Текущий"]')}}</span>
                  </h3>
                  <div class="tariff__info">
                    <ul class="tariff__descr" style="padding-left:24px;">
                      <li>{{$t('tariff["Бесплатно"]')}}</li>
                      <li class="tariff__disadvantage">{{$t('tariff["Рекламный баннер внизу вашего лендинга"]')}}</li>
                    </ul>
                    <strong class="tariff__price">
                      ${{tariffs[0] && tariffs[0].price}}
                    </strong>
                  </div>
                  <div class="tariff__footer">
                    <input class="tariff__radio"
                           type="radio"
                           name="tariff"
                           :value="1"
                           v-model="tariff"
                           @change="changeTariff"
                    >
                    <div class="tariff__text" v-if="tariff == 1">{{$t('tariff["Выбран"]')}}</div>
                    <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>
                  </div>
                </label>
              </swiper-slide>
<!--              <swiper-slide style="width: 70vw;">-->
<!--                <label style="width: 70vw;" class="tariff__item"-->
<!--                       :class="{active: tariff == 2}"-->
<!--                >-->
<!--                  <h3 class="tariff__title">-->
<!--                    Standart-->
<!--                    <span class="tariff__badge" v-if="current == 2">{{$t('tariff["Текущий"]')}}</span>-->
<!--                  </h3>-->
<!--                  <div class="tariff__info">-->
<!--                    <ul class="tariff__descr" style="padding-left:24px;">-->
<!--                      <li>{{$t('tariff["Полный доступ к статистике"]')}}</li>-->
<!--                      <li>Full access to reviews</li>-->
<!--                      <li>{{$t('tariff["Нет рекламного баннера на вашем лендинге"]')}}</li>-->
<!--                    </ul>-->
<!--                    <strong class="tariff__price">-->
<!--                      ${{tariffs[0] && tariffs[1].price}}-->
<!--                      &lt;!&ndash; <span>/ {{$t('tariff["месяц"]')}}</span> &ndash;&gt;-->
<!--                    </strong>-->
<!--                  </div>-->
<!--                  <div class="tariff__footer">-->
<!--                    <input class="tariff__radio"-->
<!--                           type="radio"-->
<!--                           name="tariff"-->
<!--                           :value="2"-->
<!--                           v-model="tariff"-->
<!--                           @change="changeTariff"-->
<!--                    >-->
<!--                    <div class="tariff__text" v-if="tariff == 2">{{$t('tariff["Выбран"]')}}</div>-->
<!--                    <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>-->
<!--                  </div>-->
<!--                </label>-->
<!--              </swiper-slide>-->
              <swiper-slide style="width: 70vw;">
                <label style="width: 70vw;" class="tariff__item"
                       :class="{active: tariff == 3}"
                >
                  <h3 class="tariff__title">
                    Pro
                    <span class="tariff__badge" v-if="current == 3">{{$t('tariff["Текущий"]')}}</span>
                  </h3>
                  <div class="tariff__info">
                    <ul class="tariff__descr" style="padding-left:24px;">
                      <li>management</li>
                      <li>Domain</li>
                      <li>Full access to reviews</li>
                      <li>PWA</li>
                      <li>Custom scripts</li>
                      <li>Weather</li>
                    </ul>
                    <strong class="tariff__price">
                      ${{tariffs[0] && tariffs[2].price}}
                      <!-- <span>/ {{$t('tariff["месяц"]')}}</span> -->
                    </strong>
                  </div>
                  <div class="tariff__footer">
                    <input class="tariff__radio"
                           type="radio"
                           name="tariff"
                           :value="3"
                           v-model="tariff"
                           @change="changeTariff"
                    >
                    <div class="tariff__text" v-if="tariff == 3">{{$t('tariff["Выбран"]')}}</div>
                    <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>
                  </div>
                </label>
              </swiper-slide>
              <swiper-slide style="width: 70vw;">
                <label class="tariff__item" style="width: 70vw;">
                  <h3 class="tariff__title">
                    Special
                  </h3>
                  <div class="tariff__info">
                    <ul class="tariff__descr" style="padding-left:24px;">
                      <li>Want something unique or extra? <router-link :to="{name:'Contact'}">Write to us</router-link></li>
                    </ul>
                  </div>
                </label>
              </swiper-slide>
            </swiper>
          </div>
          <div v-else class="tariff__list">
            <label class="tariff__item"
                   :class="{active: tariff == 1}"
            >
              <h3 class="tariff__title">
                Free
                <span class="tariff__badge" v-if="current == 1">{{$t('tariff["Текущий"]')}}</span>
              </h3>
              <div class="tariff__info">
                <ul class="tariff__descr" style="padding-left:24px;">
                  <li>{{$t('tariff["Бесплатно"]')}}</li>
                  <li class="tariff__disadvantage">{{$t('tariff["Рекламный баннер внизу вашего лендинга"]')}}</li>
                </ul>
                <strong class="tariff__price">
                  ${{tariffs[0] && tariffs[0].price}}
                </strong>
              </div>
              <div class="tariff__footer">
                <input class="tariff__radio"
                       type="radio"
                       name="tariff"
                       :value="1"
                       v-model="tariff"
                       @change="changeTariff"
                >
                <div class="tariff__text" v-if="tariff == 1">{{$t('tariff["Выбран"]')}}</div>
                <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>
              </div>
            </label>
<!--            <label class="tariff__item"-->
<!--                   :class="{active: tariff == 2}"-->
<!--            >-->
<!--              <h3 class="tariff__title">-->
<!--                Standart-->
<!--                <span class="tariff__badge" v-if="current == 2">{{$t('tariff["Текущий"]')}}</span>-->
<!--              </h3>-->
<!--              <div class="tariff__info">-->
<!--                <ul class="tariff__descr" style="padding-left:24px;">-->
<!--                  <li>{{$t('tariff["Полный доступ к статистике"]')}}</li>-->
<!--                  <li>Full access to reviews</li>-->
<!--                  <li>{{$t('tariff["Нет рекламного баннера на вашем лендинге"]')}}</li>-->
<!--                </ul>-->
<!--                <strong class="tariff__price">-->
<!--                  ${{tariffs[0] && tariffs[1].price}}-->
<!--                  &lt;!&ndash; <span>/ {{$t('tariff["месяц"]')}}</span> &ndash;&gt;-->
<!--                </strong>-->
<!--              </div>-->
<!--              <div class="tariff__footer">-->
<!--                <input class="tariff__radio"-->
<!--                       type="radio"-->
<!--                       name="tariff"-->
<!--                       :value="2"-->
<!--                       v-model="tariff"-->
<!--                       @change="changeTariff"-->
<!--                >-->
<!--                <div class="tariff__text" v-if="tariff == 2">{{$t('tariff["Выбран"]')}}</div>-->
<!--                <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>-->
<!--              </div>-->
<!--            </label>-->
            <label class="tariff__item"
                   :class="{active: tariff == 3}"
            >
              <h3 class="tariff__title">
                Pro
                <span class="tariff__badge" v-if="current == 3">{{$t('tariff["Текущий"]')}}</span>
              </h3>
              <div class="tariff__info">
                <ul class="tariff__descr" style="padding-left:24px;">
                  <li>management</li>
                  <li>Domain</li>
                  <li>Full access to reviews</li>
                  <li>PWA</li>
                  <li>Custom scripts</li>
                  <li>Weather</li>
                </ul>
                <strong class="tariff__price">
                  ${{tariffs[0] && tariffs[2].price}}
                  <!-- <span>/ {{$t('tariff["месяц"]')}}</span> -->
                </strong>
              </div>
              <div class="tariff__footer">
                <input class="tariff__radio"
                       type="radio"
                       name="tariff"
                       :value="3"
                       v-model="tariff"
                       @change="changeTariff"
                >
                <div class="tariff__text" v-if="tariff == 3">{{$t('tariff["Выбран"]')}}</div>
                <div class="tariff__text" v-else>{{$t('tariff["Выбрать"]')}}</div>
              </div>
            </label>
            <label class="tariff__item" >
              <h3 class="tariff__title">
                Special
              </h3>
              <div class="tariff__info">
                <ul class="tariff__descr" style="padding-left:24px;">
                  <li>Want something unique or extra? <router-link :to="{name:'Contact'}">Write to us</router-link></li>
                </ul>
              </div>
            </label>
          </div>
        </div>
        <div v-else v-html="settings.tariff.default_text">
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/swiper-bundle.css'
export default {
    name: 'ObjectTariff',
    props: {
        current: Number,
    },
    data() {
        return {
          swiperOptions:{
            slidesPerView: 'auto',
            spaceBetween: 10,
          },
          isMobile:false,
            tariff: this.current || 1,
            tariffs: [
              {
                "id": 1,
                "name": "FREE",
                "price": "0"
              },
              {
                "id": 2,
                "name": "STANDART",
                "price": "70"
              },
              {
                "id": 3,
                "name": "PRO",
                "price": "7"
              }
            ],
            loading: true,
        }
    },
    async created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        this.loading = false;
    },
    watch: {
        current() {
            this.tariff = this.current;
        }
    },
  computed:{
    ...mapState(['settings']),
  },
  components:{
    Swiper, SwiperSlide
  },
    methods: {
        changeTariff() {
            this.$emit('changeTariff', this.tariff)
        }
    }
};
</script>

<style lang="scss">

.tariff__list {
    @include row;
    align-items: flex-start;
}

.tariff__item {
    @include col(4);
    @include box;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &.active {
        border-color: transparent;
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: 5px;
            border: solid 4px #3fa535;
        }
    }
}

.tariff__title {
    position: relative;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 5px 5px 0 0;
    background-color: #fafafa;
}

.tariff__badge {
    position: absolute;
    top: 20px;
    right: 20px;

    padding: 5px 10px;
    border-radius: 12px;
    background-color: $primary;

    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #fff;
}

.tariff__info {
    padding: 20px;
}

.tariff__descr {
    @include reset-list;
    font-weight: 500;
    font-size: 16px;
    li {
        margin-bottom: 20px;
        &::before {
            content: '✓';
            font-family: inherit;
            margin-left: -22px;
            margin-right: 10px;
            color: #3fa535;
        }
    }
    .tariff__disadvantage {
        color: #7f8385;
        &::before {
            content: '•';
            color: #7f8385;
        }
    }
}



.tariff__price {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.23;
    span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #7f8385;
    }
}

.tariff__footer {
    position: relative;
    padding: 20px;
    border-top: 1px solid #fafafa;
}

.tariff__radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    &:checked + .tariff__text {
        &::after {
            opacity: 1;
        }
    }
}

.tariff__text {
    position: relative;
    padding-left: 30px;
    font-weight: 500;
    font-size: 16px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #e4e4e4;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #3fa535 url(~@/assets/icons/check.svg) center/11px no-repeat;
        opacity: 0;
    }
}
</style>

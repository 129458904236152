import $axios from "@/plugins/axios";

const end_point = `owner-lite-label`
const liteLabelsService = {
  async getLabelsByHotelId(hotel_id){
    try{
      const {data} = await $axios.get(`${end_point}s/${hotel_id}`);
      return data;
    }catch (e){
      return e;
    }
  },
  async getLabelById(id){
    try{
      const {data} = await $axios.get(`${end_point}/${id}`);
      return data;
    }catch (e){
      return e;
    }
  },
  async postLabel(label){
    try{
      const {data} = await $axios.post(`${end_point}`,label);
      return data;
    }catch (e){
      return e;
    }
  },
  async putLabel(label){
    try{
      const {data} = await $axios.put(`${end_point}/${label.id}`,label);
      return data;
    }catch (e){
      return e;
    }
  },
  async deleteLabel(id){
    try{
      const {data} = await $axios.delete(`${end_point}/${id}`);
      return data;
    }catch (e){
      return e;
    }
  },
  async getLabelTemplates(){
    try{
      const {data} = await $axios.get(`admin-lite-labels`);
      return data;
    }catch (e){
      return e;
    }
  }
};

export default liteLabelsService;
